@import '@/assets/scss/_variables.scss';

.Balances_root {
  height: inherit;
}

.Balances_wrapper {
  min-height: calc(100vh - 200px);
}

.Balances_tabel {
  width: 100%;
}

.Balances_charts {
  @media only screen and (min-width: $tablet-width) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.Balances_chart {
  @media only screen and (min-width: $tablet-width) {
    flex: 0 1 calc(50% - 10px);
  }
  min-height: 250px;
  padding: 35px 0;
  background: #2d4d70;
  box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.14);
  border-radius: 4px;
  margin-bottom: 20px;
}

.Balances_tabel {
  .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: #203a56;
  }

  .v-data-table > .v-data-table__wrapper tbody tr:first-child:hover td:last-child {
    border-radius: 0;
  }

  .v-data-table > .v-data-table__wrapper tbody tr:first-child:hover td:first-child {
    border-radius: 0;
  }

  .v-data-table__wrapper {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    i {
      font-size: 0;
      width: 6px;
      height: 4px;
      position: relative;
      overflow: hidden;
      color: transparent !important;
      margin-bottom: 2px;

      &::after {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 3px 4px 3px;
        border-color: transparent transparent #2af3f3 transparent;
      }
    }

    border-bottom: none;

    span {
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      margin: 0 4px 0 0;
    }
  }

  .v-data-table-header {
    background: #2d4d70;
    box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.14);
  }

  tbody {
    tr {
      background: #203a56;

      td {
        border-bottom: solid 1px #224d66;
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        text-transform: uppercase;
        line-height: 14px;

        .Balances_table-btn {
          width: 60px;
          height: 23px;
          background: #2af3f3;
          box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
          border-radius: 1.6px;
          color: #2d4d70;
          text-transform: lowercase;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: 0.25s all ease-in-out;
          &:hover {
            background: #a5ffff;
            box-shadow: 0px 0px 10px rgb(42 243 243 / 55%);
          }

          &Disabled {
            opacity: 0.3;
            pointer-events: none;
          }
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}
