@import '@/assets/scss/_mixins.scss';

.InlineSelectWrapper {
  label {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: left;
  }
  &--inputSelect {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.InlineSelect_root.v-select.v-text-field--solo {
  & > .v-input__control {
    min-height: 46px;

    & > .v-input__slot {
      background-color: rgba(#2af3f3, 0.1);
      box-shadow: none !important;

      .v-input__append-inner {
        .v-input__icon.v-input__icon--append {
          .v-icon.theme--dark {
            color: #2af3f3 !important;
          }
        }
      }

      .v-select__slot {
        .v-select__selections {
          .v-select__selection {
            color: #2af3f3 !important;
          }
        }
      }
    }
  }

  &.InlineSelect_root--inputSelect.v-select {
    max-width: 80px;

    & > .v-input__control {
      & > .v-input__slot {
        padding: 0;
        background-color: transparent;

        .v-select__selections {
          .v-select__selection {
            margin-right: 0;
            min-width: 50px;
            text-align: right;
            color: #2af3f3 !important;
          }

          input {
            display: none;
          }
        }

        .v-input__append-inner {
          padding-left: 0;
        }
      }
    }
  }
}

.v-menu__content.theme--dark.menuable__content__active {
  min-width: 76px;
  
  .v-list.v-select-list.v-sheet.theme--dark {
    background-color: #1f3347;
    text-align: left;

    .v-list-item.v-list-item--link.theme--dark {
      color: #2af3f3;

      &.v-list-item--active {
        background-color: rgba(#2af3f3, 0.1);

        .v-list-item__content {
          .v-list-item__title {
            color: #2af3f3;
          }
        }
      }
    }
  }
}
