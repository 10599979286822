@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';





















































































































































































































































































@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
@import './Balances.scss';

.Balances_info-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 0 auto 15px;
  padding-left: 15px;
  &--blue {
    color: #2af3f3;
    background: rgba(#2af3f3, 0.06);
    border-radius: 2px;
  }
  img {
    flex: 0 1 16px;
    width: 16px;
    margin-right: 5px;
  }
  span {
    display: block;
    font-size: 18px;
    line-height: 44px;
    padding: 0 10px;
  }
  .Balances_chart-select {
    max-width: 100px;
  }
  .Balances_info-label {
    line-height: 46px;
    font-size: 18px;
  }
}

.balance_filter-select {
  outline: none;
  box-shadow: none;
  color: white;
  option {
    background: #102032;
  }
}
